import React from "react";
import { Link } from "react-router-dom";
import { Partnericon } from "../../UI/icons";
import { BsArrowRightShort } from "react-icons/bs";
import styles from "./.module.scss";

const JoinUs = () => {
  return (
    <div className={styles.section}>
      <div className={styles.section__title}>
        <div className={styles.box__title}>
          <h3 className={styles.title}>
            Become A Pick-A <span>Restaurant Partner</span>
          </h3>
          <p className={styles.subtitle}>
            Join Pick .a and expand your reach to hungry customers!
          </p>
          <p className={styles.title__}>
            <span>Restaurant</span>
            <span>Items</span>
            <span>Average time</span>
          </p>
        </div>
        <div className={styles.box__title}>
          <h3 className={styles.title}>
            Why you should be <span>A Pick.a partner?</span>
          </h3>
          <p className={styles.subtitle}>
            Arising from a customer Centric mindset , We designed a full cycle
            to provide you with the best service.{" "}
          </p>
        </div>
        <div className={styles.box__title}>
          <h3 className={styles.title}>
            Our Unique <span>Features</span>
          </h3>
          <div className="row mt-4">
            <div className="col-md-4">
              <div className={styles.feature__card}>
              <span>Marketing Team</span>
                We will be your media production company, taking
                photos of your items to demonstrate how delicious your food is.
              </div>
            </div>

            <div className="col-md-4">
              <div className={styles.feature__card}>
              <span>get more customers</span>
              You can upload your entire menu to reach out to
              more customers with diverse tastes.
              </div>
            </div>

            <div className="col-md-4">
              <div className={styles.feature__card}>
              <span>High rating</span>
              Get the best customers feedback with our service to
              boost your credibility.
              </div>
            </div>

          </div>
        </div>
        <div className={styles.box__title}>
          <h3 className={styles.title}>
            How to <span>Join?</span>
          </h3>
          <ul className={`${styles.feature__list} ${styles.how__list}`}>
            <li className={styles.feature}>
              <span className={styles.steps}>1</span>
              Fill the form sign up and fill the form as a restaurant
            </li>
            <li className={styles.feature}>
              <span className={styles.steps}>2</span>Place your menu Upload your
              items
            </li>
            <li className={styles.feature}>
              <span className={styles.steps}>3</span>
              Receive your tablet Get our tablet to manage your orders
            </li>
            <li className={styles.feature}>
              <span className={styles.steps}>4</span>
              Get orders Receive your customers’ orders
            </li>
          </ul>
        </div>
        <div className={styles.box__title}>
          <h3 className={styles.title}>
            Become A Pick.a Partner <span>NOW</span>
          </h3>
        </div>
      </div>
      <div className={styles.section__contetn}>
        <div className={styles.box__container}>
          <div className={styles.box}>
            <div className={styles.box__img}>
              <img src="/assets/Rectangle 17541@2x.png" alt="" />
              <Partnericon />
            </div>
            <h4 className={styles.box__title}>Become a partner</h4>
            <p className={styles.box__desc}>
              Reach more customers and achieve growth with us
            </p>
            <Link to="/partner" className={styles.box__link}>
              Find Out More
              <span className={styles.icon}>
                <BsArrowRightShort />
              </span>
            </Link>
          </div>
        </div>
        <div className={styles.box__container}>
          <div className={styles.box}>
            <div className={styles.box__img}>
              <img
                src="/assets/delivery-man-giving-delivery-parcel-to-the-owner-3323742-2791571@2x.png"
                alt=""
              />
              {/* <Partnericon /> */}
            </div>
            <h4 className={styles.box__title}>Build your career</h4>
            <p className={styles.box__desc}>
              Join the dynamic team that makes it all happen
            </p>
            <href
              to="/"
              className={`${styles.box__link} ${styles.box__link__soon}`}
            >
              {/* Find Out More */}
              Soon
              <span className={styles.icon}>
                <BsArrowRightShort />
              </span>
            </href>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinUs;
