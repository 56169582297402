import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getMealsCategories, clearReducer } from "../../redux/actions";
import LoadWrapper from "../../components/LoadWrapper";
import { Helmet } from "react-helmet";
import PageHeader from "../../components/PageHeader";
import { Link } from "react-router-dom";
import styles from "./.module.scss";

const mapStateToProps = (state) => state;
const mapDispatchToProps = {
  getMealsCategories,
  clearReducer,
};
const AllCategories = ({
  getMealsCategories,
  mealsCategories,
  clearReducer,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await getMealsCategories();
      setIsLoading(false);
    })();

    return () => {
      clearReducer({ type: "CLEAR__MEALS__CATEGORIES", payload: [] });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadWrapper />
      ) : (
        <div className="popular__restaurants__page__wrapper py-5">
          <Helmet>
            <title>Pick.a | Categories</title>
          </Helmet>
          <PageHeader title={"Choose your meal today"} />
          <div className={styles.meals__container}>
            {mealsCategories.length ? (
              mealsCategories.map((item, i) => (
                <div className={styles.meal__card__wrapper} key={i}>
                  <Link to={`/${item.id}`} className={styles.meal__card}>
                    <div className="row g-0 w-100">
                      <div className="col">
                        <div className={styles.meal__description__wrapper}>
                          <h3 className={styles.meal__title}>{item?.name}</h3>
                          <span className={styles.meal__dish}>
                            {item?.category?.name}
                          </span>
                          <div className={styles.meal__facts}></div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className={styles.meal__image_wrapper}>
                        <img
                          className={styles.meal__img}
                          src={item?.image.replace(".netassets", ".net/assets/")}
                          alt={item?.name}
                        />
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <div className="col">
                NO MEALS CATEGORIES AVAILABLE IN YOUR AREA
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AllCategories);
