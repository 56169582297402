import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { Link } from "react-router-dom";
import { geocodeByAddress } from "react-places-autocomplete";
import FormInput from "../../components/FormInput";
import SubmitBtn from "../../components/SubmitBtn";
import GoogleMap from "./GoogleMap";
import { partnerRegister } from "../../redux/actions";
import { WhiteLogo } from "../../UI/icons";
import validators from "../../validators";
import PlacesAutocomplete from "react-places-autocomplete";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { connect } from "react-redux";
import NotificationPopUp from "../../components/NotificationPopUp";

import styles from "./.module.scss";

const registerFormElements = [
  {
    type: "text",
    icon: "user",
    name: "store_name",
    placeholder: "Store Name",
    validate: validators.composeValidators(
      validators.required("store name"),
      // validators.minLength("store_name", 1),
      validators.alphaNumeric
    ),
  },
  {
    type: "text",
    icon: "user",
    name: "no_branch",
    placeholder: "Number Of Branch",
    validate: validators.composeValidators(
      validators.required("number of branch")
    ),
  },
  {
    type: "text",
    icon: "user",
    name: "website",
    placeholder: "Website",
    validate: validators.composeValidators(validators.required("website")),
  },
  {
    type: "text",
    icon: "user",
    name: "name",
    placeholder: "Full Name",
    validate: validators.composeValidators(
      validators.required("Username"),
      validators.minLength("Username", 6),
      validators.alphaNumeric
    ),
  },
  {
    type: "email",
    icon: "email",
    name: "email",
    placeholder: "Email Address",
    validate: validators.composeValidators(
      validators.required("Email"),
      validators.email
    ),
  },
  {
    type: "number",
    icon: "mobile",
    name: "phone",
    placeholder: "Mobile Number",
    validate: validators.composeValidators(
      validators.required("Mobile Number"),
      validators.phoneNumber
    ),
  },

  {
    type: "password",
    icon: "password",
    name: "password",
    placeholder: "Password",
    validate: validators.composeValidators(
      validators.required("Password"),
      validators.minLength("Password", 6)
    ),
  },
  {
    type: "password",
    icon: "password",
    name: "confirm_password",
    placeholder: "Confirm Password",
    validate: validators.composeValidators(
      validators.required("confirm password"),
      validators.minLength("confirm password", 6)
    ),
  },
];

const mapStateToProps = (state) => state;
const mapDispatchToProps = { partnerRegister };

const PatnerPage = ({ partnerRegister }) => {
  const [address, setAddress] = useState("");
  const [lng, setLng] = useState();
  const [lat, setLat] = useState();

  const getCurrentLocation = () => {
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(function (position) {
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      });
    }
  };

  useEffect(() => {
    getCurrentLocation();
  }, []);

  const handleChange = async (address) => {
    setAddress(address);
  };

  const handleSelect = async (address) => {
    const results = await geocodeByAddress(address);
    setAddress(results[0]?.formatted_address);
    setLat(results[0]?.geometry?.location?.lat());
    setLng(results[0]?.geometry?.location?.lng());
  };

  const onSubmit = async (values, props) => {
    const data = {
      store_name: values.store_name,
      no_branch: values.no_branch,
      website: values.website,
      address,
      lat,
      long: lng,
      name: values.name,
      email: values.email,
      phone: values.phone,
      password: values.password,
      confirm_password: values.confirm_password,
    };
    const res = await partnerRegister(data);
    if (res?.data?.success) {
      props.reset();
    }
  };

  return (
    <>
      <div className={`d-flex align-items-center ${styles.partner__page}`}>
        <div className={styles.left}>
          <div className={styles.left__container}>
            <div className={styles.overlay}>
              <img src="../assets/overlay.png" alt="" />
            </div>
            <Link to="/" className={styles.logo}>
              <WhiteLogo />
            </Link>
            <div className={styles.left__content}>
              <h3 className={styles.title}>
                Welcome to Pick.a <br /> where food delivery meets quality
              </h3>
              <p className={styles.subtitle}>
                Join us now and be part of the fastest-growing food delivery app in Dubai.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.right__content}>
            <h4 className={styles.form__title}>
              Join the Pick.a family today and start growing your business
            </h4>
            <p className={styles.form__subtitke}>
              Ready to take your restaurant to the next level? become part of our mission to deliver happiness, one meal at a time.
            </p>
            <Form
              onSubmit={onSubmit}
              validate={(values) => {
                const errors = {};
                if (values.confirm_password) {
                  if (values.confirm_password !== values.password) {
                    errors.confirm_password =
                      "Password and confirmation must be matched";
                  }
                }
                return errors;
              }}
            >
              {({ handleSubmit, submitting }) => (
                <form
                  id="user__register__form"
                  className={styles.form__register}
                  onSubmit={handleSubmit}
                >
                  <div
                    className={`${styles.inputs__container} d-flex flex-column`}
                  >
                    {registerFormElements.map((item, i) => (
                      <Field
                        key={i}
                        component={FormInput}
                        placeholder={item.placeholder}
                        icon={item.icon}
                        type={item.type}
                        name={item.name}
                        validate={item.validate}
                      />
                    ))}
                    <div className={styles.google__map}>
                      <div className={styles.search__block}>
                        <PlacesAutocomplete
                          value={address || ""}
                          onChange={handleChange}
                          onSelect={handleSelect}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <div className={styles.location__search__form}>
                                <input
                                  {...getInputProps({
                                    placeholder:
                                      "Find a location, a street, or a landmark...",
                                    className: styles.search__input,
                                  })}
                                />
                              </div>
                              <div
                                className={`${styles.results__container} autocomplete-dropdown-container`}
                              >
                                {loading && (
                                  <div className={styles.loading}>
                                    <div className="text-center">
                                      <div
                                        className={`spinner-border ${styles.spinner}`}
                                        role="status"
                                      ></div>
                                      <div className="py-3">Please Wait!</div>
                                    </div>
                                  </div>
                                )}
                                {!loading &&
                                  suggestions?.map((suggestion, i) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";
                                    return (
                                      <div
                                        key={i}
                                        {...getSuggestionItemProps(suggestion, {
                                          className: `${className} ${styles.contain}`,
                                        })}
                                      >
                                        <span className={styles.icon}>
                                          <HiOutlineLocationMarker />
                                        </span>
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </div>
                      <div className={styles.map}>
                        <GoogleMap
                          lat={lat}
                          lng={lng}
                          setLng={setLng}
                          setLat={setLat}
                          setAddress={setAddress}
                        />
                      </div>
                    </div>
                  </div>
                  <SubmitBtn
                    smBorder={true}
                    solidStyle={true}
                    type="submit"
                    disabled={submitting}
                  >
                    Register
                  </SubmitBtn>
                </form>
              )}
            </Form>
          </div>
        </div>
      </div>
      <NotificationPopUp />
    </>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(PatnerPage);
