import React, { useEffect, useState } from "react";
import ItemDetailsRow from "../ItemDetailsRow";
import ResturantDetailsHeader from "../RestaurantDetailsHeader";
import RestaurantInfoList from "../RestaurantInfoList";
import RestaurantCategoriesList from "../RestaurantCategoriesList";
import RestaurantMealCard from "../RestaurantMealCard";
import styles from "./.module.scss";
import { useRef } from "react";

const SingleRestaurant = ({ restaurantDetails }) => {
  const [currentActive, setCurrentActive] = useState(0);
  const [content, setContent] = useState("details");
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setloading] = useState(true);
  const [startSearch, setStartSearch] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [term, setTerm] = useState("");
  const ref = useRef(null);

  useEffect(() => {
    setloading(true);
    const timeOut = setTimeout(() => {
      setloading(false);
    }, 0);
    if (currentActive) {
      setFilteredItems(restaurantDetails?.categories[currentActive].items);
    } else {
      if (restaurantDetails?.categories) {
        setFilteredItems(restaurantDetails?.categories[0]?.items);
      }
    }

    return () => {
      clearTimeout(timeOut);
    };
  }, [currentActive, restaurantDetails.categories]);

  useEffect(() => {
    const itemsCategories = [];
    restaurantDetails.categories.map((category) =>
      itemsCategories.push(...category.items)
    );
    setAllItems(itemsCategories);
    setStartSearch(itemsCategories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (term) {
      setStartSearch(true);
      setCurrentActive(null);
    } else {
      setStartSearch(false);
      setCurrentActive(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allItems, term]);

  useEffect(() => {
    setSearchResults(allItems);
    const filteredSearch = allItems.filter((item) =>
      item.name.toLowerCase().includes(term.toLowerCase())
    );
    setSearchResults(filteredSearch);
  }, [allItems, term]);

  return (
    <>
      <ResturantDetailsHeader
        item={restaurantDetails?.restaurant}
        setTerm={setTerm}
        term={term}
      />
      <div className="row">
        <div className="d-xl-none">
          <ItemDetailsRow content={content} setContent={setContent} />
        </div>
        {content === "details" ? (
          <>
            <div className="order-2 order-xl-1 col-12 col-xl-8 pe-lg-4">
              <div className="pt-4 pt-lg-0">
                <div div className="d-none d-xl-block">
                  <ItemDetailsRow content={content} setContent={setContent} />
                </div>
                <div className={styles.items__container} ref={ref}>
                  {restaurantDetails?.categories &&
                  loading ? null : startSearch ? (
                    searchResults?.length ? (
                      searchResults?.map((item, i) => (
                        <RestaurantMealCard key={i} item={item} />
                      ))
                    ) : (
                      <h4>NO RESTAURANTS</h4>
                    )
                  ) : (
                    filteredItems?.map((item, i) => (
                      <RestaurantMealCard key={i} item={item} />
                    ))
                  )}
                </div>
              </div>
            </div>
            <div
              className={`order-1 order-xl-2 col-12 col-xl-4 ps-lg-4 ${styles.categories__container}`}
            >
              <div
                className="pt-1 pb-4 pb-xl-0 pt-xl-5"
                style={{ position: "sticky", top: "-2rem" }}
              >
                {restaurantDetails?.categories?.length ? (
                  <RestaurantCategoriesList
                    top={ref?.current?.offsetTop}
                    categories={restaurantDetails.categories}
                    setCurrentActive={setCurrentActive}
                    currentActive={currentActive}
                    setFilteredItems={setFilteredItems}
                    setStartSearch={setStartSearch}
                  />
                ) : (
                  <>NO RESTAURANT CATEGORIES AVAILABLE</>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="d-none d-xl-block">
              <ItemDetailsRow content={content} setContent={setContent} />
            </div>
            <RestaurantInfoList item={restaurantDetails.restaurant} />
          </>
        )}
      </div>
    </>
  );
};

export default SingleRestaurant;
