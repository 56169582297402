import axios from "axios";
import config from "../config";
export default axios.create({
  baseURL: config.SERVER_API_URL || "https://dashboard.pick-a.net/api",
  // baseURL: config.SERVER_API_URL || "https://dev.pick-a.net/api",
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem("auth_token")}`,
    latitude: JSON.parse(window.localStorage.getItem("location"))?.lat,
    longitude: JSON.parse(window.localStorage.getItem("location"))?.lng,
  },
});
