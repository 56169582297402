const mealsCategoriesReducer = (data = {}, action) => {
  switch (action.type) {
    case "GET__MEALS__CATEGORIES":
      return action?.payload;
    case "CLEAR__MEALS__CATEGORIES":
      return action?.payload;
    default:
      return data;
  }
};

export default mealsCategoriesReducer;
