import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";

export const GoogleMap = ({ lat, lng, setAddress, setLng, setLat }) => {
  const [mapCenter, setMapCenter] = useState({
    lat: lat || JSON.parse(localStorage?.getItem("currentLocation"))?.lat,
    lng: lng || JSON.parse(localStorage?.getItem("currentLocation"))?.lng,
  });
  const [zoom, setZoom] = useState(10);
  const [handleApiLoaded, setHandleApiLoaded] = useState(() => {});
  const image = "https://www.talabat.com/assets/images/marker.svg";
  useEffect(() => {
    setZoom(10);
    setMapCenter({
      lat: lat || JSON.parse(localStorage?.getItem("currentLocation"))?.lat,
      lng: lng || JSON.parse(localStorage?.getItem("currentLocation"))?.lng,
    });
    setHandleApiLoaded(() => (map, maps) => {
      let marker;
      marker = new maps.Marker({
        position: mapCenter,
        map: map,
        icon: image,
      });
      map.addListener("center_changed", function () {
        marker.setPosition(map.getCenter());
      });
      map.addListener("dragend", async function () {
        const geocoder = new maps.Geocoder();
        const geoCode = await geocoder.geocode({
          location: { lat: map.center.lat(), lng: map.center.lng() },
        });
        const address = geoCode.results[0].formatted_address;
        setAddress(address);
        setLat(map.center.lat());
        setLng(map.center.lng());
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, lng]);

  return (
    <GoogleMapReact
      center={mapCenter}
      defaultZoom={zoom}
      yesIWantToUseGoogleMapApiInternals={true}
      onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
    />
  );
};

export default GoogleMap;
