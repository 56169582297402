const loggedPartnerReducer = (partner = {}, action) => {
  switch (action.type) {
    // case "USER__REGISTER__ACTION":
    // return { ...loggedUser, ...action?.payload.data };
    // case "USER__LOGIN__ACTION":
    // return { ...loggedUser, ...action?.payload.data };
    case "PARTNER__REGISTER__ACTION":
      return { ...partner, ...action?.payload };
    default:
      return { ...partner };
  }
};

export default loggedPartnerReducer;
