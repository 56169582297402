import api from "../../../api";

const getMostSearch = () => async (dispatch) => {
  try {
    const res = await api.get("/mostSearch", {
      latitude: JSON.parse(window.localStorage.getItem("location"))?.lat,
      longitude: JSON.parse(window.localStorage.getItem("location"))?.lng,
    });
    dispatch({
      type: "GET__MOST__SEARCH",
      payload: [...res.data.data.MostSearchWord],
    });
  } catch (error) {
    dispatch({
      type: "GET__MOST__SEARCH",
      payload: [],
    });
  }
};

export default getMostSearch;
