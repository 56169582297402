import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getMealsCategory, clearReducer } from "../../redux/actions";
import LoadWrapper from "../../components/LoadWrapper";
import { Helmet } from "react-helmet";
import PageHeader from "../../components/PageHeader";
import styles from "./.module.scss";
import CategoryMealCard from "../../components/CategoryMealCard";

const mapStateToProps = (state) => state;
const mapDispatchToProps = {
  getMealsCategory,
  clearReducer,
};

const SingleCategory = ({ getMealsCategory, meals, clearReducer }) => {
  const { categoryId } = useParams();
  const [limit, setLimit] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const [noMore, setNoMore] = useState(false);

  useEffect(() => {
    setLoadMore(true);

    (async () => {
      const data = await getMealsCategory({
        id: categoryId,
        skip: limit * 10,
      });
      if (!data.length) {
        setNoMore(true);
      }
      setIsLoading(false);
      setLoadMore(false);
    })();

    return () => {
      clearReducer({ type: "CLEAR__MEALS__CATEGORY", payload: [] });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit]);

  return (
    <>
      {isLoading ? (
        <LoadWrapper />
      ) : (
        <div className="popular__restaurants__page__wrapper py-5">
          <Helmet>
            <title>Pick.a | Meals</title>
          </Helmet>
          <PageHeader title={"Choose your meal today"} />
          <div className={styles.meals__container}>
            {meals.length ? (
              meals.map((item, i) => <CategoryMealCard key={i} item={item} />)
            ) : (
              <div className="col">
                NO MEALS CATEGORIES AVAILABLE IN YOUR AREA
              </div>
            )}
          </div>
        </div>
      )}
      {noMore ? (
        <p
          style={{
            textAlign: "center",
            fontSize: "20px",
            marginBottom: "40px",
          }}
        >
          No More Meals!
        </p>
      ) : (
        <button
          className={`${styles.show__btn} ${loadMore ? styles.loading : ""}`}
          onClick={() => setLimit((prev) => prev + 1)}
        >
          {loadMore ? <span></span> : "Show More"}
        </button>
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleCategory);
