const FilteredDataReducer = (slides = [], action) => {
  switch (action.type) {
    case "SET__FILTER__OPTIONS__RESULTS":
      return [...slides, ...action?.payload];
    case "CLEAR__FILTER__OPTIONS__RESULTS":
      return [...action?.payload];
    default:
      return [...slides];
  }
};

export default FilteredDataReducer;
