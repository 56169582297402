import api from "../../../api";

const setFilterOptions = (options) => async (dispatch) => {
  let res;
  try {
    if (!options.category_id) {
      res = await api.get(`/filter-restaurants`);
    } else {
      res = await api.get(
        `/filter-restaurants?category_id=${options?.category_id}&top_rated=${options?.top_rated}&delivery_time=${options?.delivery_time}`
      );
    }
    dispatch({
      type: "SET__FILTER__OPTIONS__RESULTS",
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: "SET__FILTER__OPTIONS__RESULTS",
      payload: [],
    });
  }
};

export default setFilterOptions;
