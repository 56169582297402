import React, { useEffect } from "react";
import styles from "./.module.scss";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import LoadWrapper from "../../components/LoadWrapper";
import { Helmet } from "react-helmet";
import RestaurantCard from "../../components/RestaurantCard";

const SinglePromoRestaurants = () => {
  const [isloading, setIsLoading] = useState(true);

  const location = useLocation();
  const { promo } = location.state;

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timeOut);
  }, []);

  if (isloading) return <LoadWrapper />;

  return (
    <>
      <Helmet>
        <title>Pick.a | {promo.name}</title>
      </Helmet>
      <div className={styles.promo__page}>
        <div className="row">
          {promo.restaurants.map((restaurant) => (
            <div
              key={restaurant.id}
              className={`col-12 col-sm-6 col-lg-4 ${styles.col}`}
            >
              <RestaurantCard type="restaurant" item={restaurant} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SinglePromoRestaurants;
