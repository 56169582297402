import React from "react";
import { Link } from "react-router-dom";
import styles from "./.module.scss";

export default function OfferCard({ item, index }) {
  const cardStyles = [
    "purple__card",
    "orange__card",
    "dark__gray__card",
    "light__gray__card",
  ];
  return (
    <Link
      to={`/promo/${item.id}`}
      state={{ promo: item }}
      className={`${styles.offer__card} ${
        styles[`${cardStyles[index < 4 ? index : index % 4]}`]
      }`}
    >
      <div className="row flex-lg-row flex-column-reverse align-items-center justify-content-between w-100 g-0">
        <div className={`${styles.right__side} col-12 col-lg-7 col-xl-4`}>
          <img
            className={styles.offer__image}
            src={item?.image.replace(".netassets", ".net/assets/")}
            alt={item?.name}
          />
        </div>
      </div>
    </Link>
  );
}
