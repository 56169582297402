import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./.module.scss";
import { clearReducer, getItemPage } from "../../redux/actions";
import { connect } from "react-redux";

const mapStateToProps = (state) => state;
const mapDispatchToProps = { clearReducer, getItemPage };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(function SearchMenu({
  restaurants,
  loading,
  clearReducer,
  getItemPage,
  onClose,
  mostSearch,
  setTerm,
}) {
  const navigate = useNavigate();

  if (loading)
    return (
      <div className={styles.searchContainer}>
        <span className={styles.lds__dual__ring}></span>
      </div>
    );
  if (mostSearch)
    return (
      <div className={styles.search__menu}>
        <div className={styles.mostSearch}>
          {mostSearch.map((word, i) => (
            <button onClick={() => setTerm(word)} key={i}>
              {word}
            </button>
          ))}
        </div>
      </div>
    );
  return (
    <div className={styles.search__menu}>
      {restaurants && restaurants.length ? (
        restaurants.map((restaurant, i) => {
          if (!restaurant?.restaurant_id) {
            return (
              <button
                onClick={() => {
                  navigate(`/restaurant/${restaurant?.slug}`);
                  onClose(false);
                }}
                className={styles.restaurant}
                key={i}
              >
                <img
                  src={`https://dashboard.pick-a.net/${restaurant?.image}`}
                  alt={restaurant?.name}
                  className={styles.res__image}
                />
                {restaurant?.name}
              </button>
            );
          } else {
            return (
              <button
                className={styles.restaurant}
                key={i}
                data-bs-toggle="modal"
                data-bs-target={`#item__details__popup`}
                onClick={() => {
                  clearReducer({ type: "ITEM__POPUP__DATA", payload: {} });
                  (async () => {
                    await getItemPage(restaurant?.id);
                  })();
                  onClose(false);
                }}
              >
                <img
                  src={`https://dashboard.pick-a.net/${restaurant?.image}`}
                  alt={restaurant?.name}
                  className={styles.res__image}
                />
                {restaurant.name}
              </button>
            );
          }
        })
      ) : (
        <div className={styles.restaurant}>Ther's No Restaurants</div>
      )}
    </div>
  );
});
