import React, { useState, useEffect } from "react";
import styles from "./.module.scss";
import RestaurantCard from "../RestaurantCard";
import { lowerCase } from "lodash";

const RestaurantsList = ({ items }) => {
  const [closedRestaurants, setClosedRestaurants] = useState([]);
  const [openedRestaurants, setOpenedRestaurants] = useState([]);

  useEffect(() => {
    const closed = [];
    const opened = [];
    items
      ?.filter((item) => item.isOpen)
      // eslint-disable-next-line array-callback-return
      .map((item) => {
        if (item?.is_schedulable) {
          const day = lowerCase(
            new Date().toLocaleDateString("en", { weekday: "long" })
          );
          const schedule_table = JSON.parse(item?.schedule_data);
          for (const DAY in schedule_table) {
            if (Object.hasOwnProperty.call(schedule_table, DAY)) {
              if (DAY === day) {
                const Hours = schedule_table[DAY];
                // eslint-disable-next-line no-loop-func
                Hours.forEach((interval) => {
                  // GET ALL ABOUT TIME NOW
                  const currentTimeNow = new Date();
                  // DETERMINE OPENING AND CLOSEING TIMES
                  const openTime = new Date();
                  openTime.setHours(interval?.open?.split(":")[0]);
                  openTime.setMinutes(interval?.open?.split(":")[1]);
                  openTime.setSeconds(0);
                  const closeTime = new Date();
                  closeTime.setHours(interval?.close?.split(":")[0]);
                  closeTime.setMinutes(interval?.close?.split(":")[1]);
                  closeTime.setSeconds(0);
                  if (
                    !(
                      currentTimeNow.getTime() > openTime.getTime() &&
                      currentTimeNow.getTime() < closeTime.getTime()
                    )
                  ) {
                    closed.push(item);
                  } else {
                    opened.push(item);
                  }
                });
              }
            }
          }
        }
      });
    setClosedRestaurants(closed);
    setOpenedRestaurants(opened);
  }, [items]);

  // console.log(
  //   closedRestaurants.map((res) => JSON.parse(res.schedule_data).friday[0])
  // );

  return (
    <>
      {openedRestaurants.map((item, i) => (
        <div key={i} className={`col-12 col-sm-6 col-lg-4 ${styles.col}`}>
          <RestaurantCard type="restaurant" item={item} closed={false} />
        </div>
      ))}
      {closedRestaurants.map((item, i) => (
        <div key={i} className={`col-12 col-sm-6 col-lg-4 ${styles.col}`}>
          <RestaurantCard type="restaurant" item={item} closed={true} />
        </div>
      ))}
      {openedRestaurants.length && closedRestaurants.length ? (
        items
          .filter((item) => !item.isOpen)
          .map((item, i) => (
            <div key={i} className={`col-12 col-sm-6 col-lg-4 ${styles.col}`}>
              <RestaurantCard type="restaurant" item={item} closed={true} />
            </div>
          ))
      ) : (
        <h1>Wait...!</h1>
      )}
    </>
  );
};

export default RestaurantsList;
