import { useEffect } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

const mapStateToProps = (state) => state;
const mapDispatchToProps = {};

export const AuthGuard = ({ loggedUser, children }) => {
  useEffect(() => {
    if (isEmpty(loggedUser)) {
      document?.getElementById("open__member__area__form").click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isEmpty(loggedUser)) {
    return (
      <>
        <h1>Need To Login</h1>
      </>
    );
  }

  if (!isEmpty(loggedUser)) {
    return children;
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthGuard);
