import React from "react";
import SwiperSlider from "../SwiperSlider";
import { SwiperSlide } from "swiper/react";
import styles from "./.module.scss";
import FavToggleBtn from "../FavToggleBtn";
import { Link } from "react-router-dom";

export default function MealsSlider({ type, items }) {
  return (
    <div>
      <SwiperSlider
        loop={false}
        paginated={false}
        centeredSlides={false}
        autoPlay={{ play: true, delay: 5000 }}
        navigation={true}
        className="meals__slider__wrapper"
        breakPoints={{
          0: {
            slidesPerView: 2,
            spaceBetween: 14,
          },
          567: {
            slidesPerView: 3.5,
            spaceBetween: 21,
          },
          992: {
            slidesPerView: 4.5,
            spaceBetween: 21,
          },
          1200: {
            slidesPerView: 5.75,
            spaceBetween: 21,
          },
          1440: {
            slidesPerView: 6.75,
            spaceBetween: 21,
          },
        }}
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>
            <div className={styles.meal__card__wrapper}>
              <Link to={`/${item.id}`} className={styles.meal__card}>
                <div className="row g-0 w-100">
                  <div className="col">
                    <div className={styles.meal__description__wrapper}>
                      <h3 className={styles.meal__title}>{item?.name}</h3>
                      <span className={styles.meal__dish}>
                        {item?.category?.name}
                      </span>
                      <div className={styles.meal__facts}></div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className={styles.meal__image_wrapper}>
                    <img
                      className={styles.meal__img}
                      src={item?.image.replace(".netassets", ".net/assets/")}
                      alt={item?.name}
                    />
                  </div>
                </div>
              </Link>
            </div>
          </SwiperSlide>
        ))}
      </SwiperSlider>
    </div>
  );
}
