import React from "react";
import styles from "./.module.scss";
import Sidebar from "../components/Sidebar";
import MainHeader from "../components/MainHeader";
import Footer from "../components/Footer";
import MobFixedBar from "../components/MobFixedBar";

// POP UP MODALS
// import MemberAreaForms from "../models/MemberAreaForms";
// import ShippingPopUp from "../models/ShippingPopUp";
// import SearchFilterPopUp from "../models/SearchFilterPopUp";
// import NotificationPopUp from "../components/NotificationPopUp";
// import ItemDetailsPopUp from "../models/ItemDetailsPopUp";
// import SupportPopUp from "../models/SupportPopUp";
// import CancelPoup from "../models/CancelPopUp";
// import AddressesPopUp from "../models/AddressesPopUp";

// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
import { Outlet } from "react-router-dom";

// const stripePromise = loadStripe(
//   "pk_test_51LNF3wCuskqUZKWT5ZQXcSa0c96FP2jFu0ubWGrTlLJbomfuPT9z9edcFa5EFK2PBFhx64xGi7MdzDrRmFGQN0ED00G981buvD"
// );

export default function Layout({ children }) {
  return (
    <>
      <main className={styles.main__layout}>
        <Sidebar />
        <div className={styles.app__main__container}>
          {/* <Elements stripe={stripePromise}> */}
          <MainHeader />
          {/* </Elements> */}
          <div className={styles.app__container}>
            <div className="container__wrapper">
              {/* {children} */}
              <Outlet />
            </div>
          </div>
          <MobFixedBar />
        </div>
        <Footer />
      </main>
    </>
  );
}
