import React from "react";
import styles from "./.module.scss";
import SwiperSlider from "../SwiperSlider";
import { SwiperSlide } from "swiper/react";
import OfferCard from "../OfferCard";

export default function OffersSlider({ items }) {
  return (
    <div className={styles.offers__slider__wrapper}>
      <SwiperSlider
        paginated={false}
        centeredSlides={true}
        loop={true}
        autoPlay={{ play: true, delay: 5000 }}
        breakPoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          567: {
            slidesPerView: 1.5,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2.5,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3.5,
            spaceBetween: 20,
          },
        }}
      >
        {items.map((item, index) => (
          <SwiperSlide key={item.id}>
            <OfferCard index={index} item={item} />
          </SwiperSlide>
        ))}
      </SwiperSlider>
    </div>
  );
}
