import React from "react";
import { Swiper } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/navigation";

export default function SwiperSlider({
  children,
  centeredSlides,
  loop,
  paginated,
  breakPoints,
  autoPlay,
  navigation,
  className,
}) {
  return (
    <Swiper
      modules={[Pagination, Autoplay, Navigation]}
      autoplay={{ enabled: autoPlay.play, delay: autoPlay.delay }}
      breakpoints={breakPoints}
      pagination={paginated && { paginated: true }}
      centeredSlides={centeredSlides || false}
      loop={loop}
      navigation={navigation}
      className={className || ""}
    >
      {children}
    </Swiper>
  );
}
