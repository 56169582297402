import React from "react";
import styles from "./.module.scss";
import { AiFillStar } from "react-icons/ai";
import { RiMotorbikeLine } from "react-icons/ri";
import { CgMathPlus } from "react-icons/cg";
import FavToggleBtn from "../FavToggleBtn";
import { connect } from "react-redux";
import { clearReducer, getItemPage } from "../../redux/actions";

const mapStateToProps = (state) => state;
const mapDispatchToProps = { clearReducer, getItemPage };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(function RestaurantMealCard({ item, clearReducer, getItemPage }) {
  return (
    <div className={styles.res__mael__card__wrapper}>
      <div
        className={styles.meal__details__container}
        data-bs-toggle="modal"
        data-bs-target={`#item__details__popup`}
        onClick={(e) => {
          clearReducer({ type: "ITEM__POPUP__DATA", payload: {} });
          (async () => {
            await getItemPage(item?.id);
          })();
          e.stopPropagation();
        }}
      >
        <div className={styles.meal__img__wrapper}>
          <img
            className={styles.meal__img}
            // src={item?.image}
            src={item?.image.replace(".netassets/", ".net/assets/")}
            alt={item?.name}
          />
        </div>
        <div className={styles.meal__details}>
          <h2 className={styles.meal__name}>{item?.name}</h2>
          <p className={styles.meal__cats}>{item?.description}</p>
        </div>
        <div className={styles.meal__card__footer}>
          <span className={styles.meal__rating}>
            <AiFillStar className={styles.star__icon} />
            {item?.rating?.toFixed(1)}
          </span>
          <div className={styles.meal__prices}>
            {Boolean(Number(item?.old_price)) && (
              <span className={styles.old__price}>
                {
                  JSON.parse(window.localStorage.getItem("currencyFormat"))
                    .value
                }
                {item?.old_price}
              </span>
            )}
            <span className={styles.current__price}>
              {!item?.addon_categories.length &&
                JSON.parse(window.localStorage.getItem("currencyFormat")).value}
              <span className={styles.value}>
                {/* {item?.price === "0.00" ? "depend on" : item?.price} */}
                {item?.addon_categories.length
                  ? "Price Of Selections"
                  : " " + item?.price}
              </span>
            </span>
          </div>
          <span className={styles.meal__cooking__time}>
            <RiMotorbikeLine className={styles.bike__icon} />
            {item?.delivery_time} MIN
          </span>
          {/* {Boolean(item?.is_new) && (
              <span className={styles.meal__cooking__time}>NEW</span>
            )}
            {Boolean(item?.is_recommended) && (
              <span className={styles.meal__cooking__time}>RECOMMENDED</span>
            )}
            {Boolean(item?.is_popular) && (
              <span className={styles.meal__cooking__time}>POPULAR</span>
            )} */}
        </div>
      </div>
      <button
        type="button"
        className={styles.add__cart__btn}
        data-bs-toggle="modal"
        data-bs-target={`#item__details__popup`}
        onClick={(e) => {
          clearReducer({ type: "ITEM__POPUP__DATA", payload: {} });
          (async () => {
            await getItemPage(item?.id);
          })();
          e.stopPropagation();
        }}
      >
        <CgMathPlus />
      </button>
      <div className={styles.like__btn__wrapper}>
        <FavToggleBtn type={"item"} item={item} />
      </div>
    </div>
  );
});
