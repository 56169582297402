import React from "react";
import { Route } from "react-router-dom";
import { Routes } from "react-router-dom";
// PAGES
import IsDeliverable from "../IsDeliverable";
import StartPage from "../../pages/StartPage";
import HomePage from "../../pages/Homepage";
import RestaurantsPage from "../../pages/AllRestaurantsPage";
import PopularRestaurantsPage from "../../pages/PopularRestaurantsPage";
import FavouritesPage from "../../pages/FavouritesPage";
import SearchResultsPage from "../../pages/SearchResultsPage";
import RestaurantDetailsPage from "../../pages/RestaurantDetailsPage";
import OrderDetailsPage from "../../pages/OrderDetailsPage";
import OrderTrackingPage from "../../pages/OrderTrackingPage";
import SupportPage from "../../pages/SupportPage";
import PrivacyPolicyPage from "../../pages/PrivacyPolicyPage";
import NotFound from "../NotFound";
import AuthGuard from "../AuthGuard";
import ChangePasswordPage from "../../pages/ChangePasswordPage";
import UserAddressesPage from "../../pages/UserAddressesPage";
import SinglePromoRestaurants from "../../pages/SinglePromoRestaurants";
import PatnerPage from "../../pages/PatnerPage";
import AllCategories from "../../pages/AllCategories";
import DeleteAccountPage from "../../pages/DeleteAccountPage";
import ConfirmDeleteAccount from "../../pages/ConfirmDeleteAccount";

// Components
import Layout from "../../layout";
import Navigation from "../Navigation";

// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/global.style.scss";
import "bootstrap/dist/js/bootstrap";
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/button";
import SingleCategory from "../../pages/SingleCategory";

// POP UP MODALS
import MemberAreaForms from "../../models/MemberAreaForms";
import ShippingPopUp from "../../models/ShippingPopUp";
import SearchFilterPopUp from "../../models/SearchFilterPopUp";
import NotificationPopUp from "../../components/NotificationPopUp";
import ItemDetailsPopUp from "../../models/ItemDetailsPopUp";
import SupportPopUp from "../../models/SupportPopUp";
import CancelPoup from "../../models/CancelPopUp";
import AddressesPopUp from "../../models/AddressesPopUp";

export default function App() {
  return (
    <>
      <Navigation />
      {/* <Layout> */}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="" element={<StartPage />} />
          <Route
            path="home"
            element={
              <IsDeliverable>
                <HomePage />
              </IsDeliverable>
            }
          />
          <Route
            path="restaurants"
            element={
              <IsDeliverable>
                <RestaurantsPage />
              </IsDeliverable>
            }
          />
          <Route
            path="popular-restaurants"
            element={
              <IsDeliverable>
                <PopularRestaurantsPage />
              </IsDeliverable>
            }
          />
          <Route
            path="all-categories"
            element={
              <IsDeliverable>
                <AllCategories />
              </IsDeliverable>
            }
          />
          <Route
            path=":categoryId"
            element={
              <IsDeliverable>
                <SingleCategory />
              </IsDeliverable>
            }
          />
          <Route
            path="favourites"
            element={
              <AuthGuard>
                <FavouritesPage />
              </AuthGuard>
            }
          />
          <Route path="search" element={<SearchResultsPage />} />
          <Route path="restaurant/:slug" element={<RestaurantDetailsPage />} />
          <Route
            path="my-order"
            element={
              <AuthGuard>
                <OrderDetailsPage />
              </AuthGuard>
            }
          />
          <Route
            path="order-tracking"
            element={
              <AuthGuard>
                <OrderTrackingPage />
              </AuthGuard>
            }
          />
          <Route path="support" element={<SupportPage />} />
          <Route path="privacy" element={<PrivacyPolicyPage />} />
          <Route
            path="change-password"
            element={
              <AuthGuard>
                <ChangePasswordPage />
              </AuthGuard>
            }
          />
          <Route
            path="addresses"
            element={
              <AuthGuard>
                <UserAddressesPage />
              </AuthGuard>
            }
          />
          <Route path="promo/:promoId" element={<SinglePromoRestaurants />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="/partner" element={<PatnerPage />} />
        <Route path="/deletion-account" element={<DeleteAccountPage />} />
        <Route path="/delete-account-confirm" element={<ConfirmDeleteAccount />} />
      </Routes>
      {/* POP UP MODALS */}
      <MemberAreaForms />
      <ShippingPopUp />
      <SearchFilterPopUp />
      <NotificationPopUp />
      <ItemDetailsPopUp />
      <SupportPopUp />
      <CancelPoup />
      <AddressesPopUp />
    </>
  );
}
