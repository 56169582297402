import api from "../../../../api";

const partnerRegister = (data) => async (dispatch) => {
  try {
    const res = await api.post("/join-owner-store", data);
    dispatch({
      type: "PARTNER__REGISTER__ACTION",
      payload: { msgType: res.data.success ? "success" : "error", ...res.data },
    });
    return res;
  } catch (error) {
    dispatch({
      type: "PARTNER__REGISTER__ACTION",
      payload: { msgType: "error", ...error.response.data },
    });
    return error.response.data;
  }
};

export default partnerRegister;
