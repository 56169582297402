// import api from "../../../api";

const checkIfAreaIsDeliverable =
  ({ lat, lng }) =>
  async () => {
    try {
      const res = await fetch(
        "https://dashboard.pick-a.net/api/check-user-area",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            latitude: +lat,
            longitude: +lng,
          },
        }
      );
      const data = await res.json();
      return data?.data?.length ? true : false;
    } catch (error) {
      return error.response.data.success;
    }
  };

export default checkIfAreaIsDeliverable;
