import React from "react";
import { Field, Form } from "react-final-form";
import styles from "./.module.scss";
import validators from "../../validators";
import FormInput from "../../components/FormInput";
import SubmitBtn from "../../components/SubmitBtn";
import api from "../../api";
import { setCustomAlert } from "../../redux/actions";
import { connect } from "react-redux";
import { PickALogo } from "../../UI/icons";
import { IoWarningOutline } from "react-icons/io5";
import { BsDot } from "react-icons/bs";
import { Link } from "react-router-dom";

const registerFormElements = [
  {
    type: "email",
    icon: "email",
    name: "email",
    placeholder: "Email Address",
    validate: validators.composeValidators(
      validators.required("Email"),
      validators.email
    ),
  },
];

const mapStateToProps = (state) => state;
const mapDispatchToProps = { setCustomAlert };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(function DeleteAcountPage({ setCustomAlert }) {
  const onSubmit = async (values, form) => {
    try {
      const res = await api.post("/delete-account/request", {
        email: values.email,
      });
      setCustomAlert({
        msgType: "success",
        message: "Check your email!",
      });
      // Reset email field after success
      form.resetFieldState('email');
      form.change('email', ''); // This sets the email field back to empty
    } catch (err) {
      setCustomAlert({
        msgType: "error",
        message: err?.response?.data?.message || "Something went wrong!",
      });
    }
  };

  return (
    <div className={styles.page}>
      <div className={`${styles.logo_box} d-flex align-items-center gap-2`}>
        <PickALogo /> <span className={styles.logo}>Pick.a</span>
      </div>
      <h5 className={styles.step_title}>Data Deletion Right</h5>
      <p className={styles.description}>
      In case a user contacts us directly or via the in-app Bug Report option, we will collect their email for the sole purpose of communication and resolving their issue. However, if the user wants to exercise their Data Deletion Right, they can request us to delete their email and associated data from our records by contacting us through the same email address. We will respond promptly to the user's request and take the necessary actions to delete their data from our records.
      </p>
      <h5 className={styles.step_title}>Instructions for Requesting Data Deletion</h5>
      <p className={styles.description}>
      To request data deletion, users may send an email to support@pick-a.net from the email address that is valid or registered with the app data. The subject line of the email should read 'Pick.a data deletion request' and the email should include all necessary details.      
      </p>
      <h5 className={styles.step_title}>Read Our <a target="_blank" href="https://pick-a.net/user-privacy.html">Privacy Policy</a> to Understand Data Use</h5>
      <p className={styles.description}>
      We take our users' privacy seriously and are committed to protecting their personal information. To better understand how your data is used by us or our app, we encourage you to read our privacy policy. Our policy provides a detailed explanation of the types of data we collect, how we use it, and who we share it with. We also outline the measures we take to ensure the security and confidentiality of your information.

By familiarizing yourself with our <a target="_blank" href="https://pick-a.net/user-privacy.html">privacy policy</a>, you can be assured that your personal information is being handled in a responsible and transparent manner. If you have any questions or concerns about our policy or how your data is being used, please do not hesitate to contact us. We value your trust and appreciate the opportunity to serve you.
      </p>
      <hr />
      <div className={styles.title_box}>
        <h3 className={styles.title}>
          <IoWarningOutline className={styles.warning_icon} />
          Delete Account
        </h3>
        <p className={styles.warning}>
          <span>Warning</span> this is premanent and cannot be undoe
        </p>
      </div>
      <h5 className={styles.step_title}>Steps to delete your account!</h5>
      <ul className={styles.steps_list}>
        <li>
          <BsDot />
          Write down your registered email address with us
        </li>
        <li>
          <BsDot />
          Go to your emails, you will find a link, click on it
        </li>
        <li>
          <BsDot />
          You will be redirected to a page to confirm the deletion of your account. If you confirm, the account will be deleted.
        </li>
      </ul>
      <Form
        onSubmit={(values, form) => onSubmit(values, form)}
        validate={(values) => {
          const errors = {};
          if (values.confirm_password) {
            if (values.confirm_password !== values.password) {
              errors.confirm_password =
                "Password and confirmation must be matched";
            }
          }
          return errors;
        }}
      >
        {({ handleSubmit, form, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div className={`${styles.inputs__container} d-flex flex-column`}>
              {registerFormElements.map((item, i) => (
                <Field
                  key={i}
                  component={FormInput}
                  placeholder={item.placeholder}
                  icon={item.icon}
                  type={item.type}
                  name={item.name}
                  validate={item.validate}
                />
              ))}
            </div>
            <SubmitBtn
              smBorder={true}
              solidStyle={true}
              type="submit"
              disabled={submitting}
            >
              Send
            </SubmitBtn>
          </form>
        )}
      </Form>
    </div>
  );
});
