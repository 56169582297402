import React, { useState, useEffect } from "react";
import styles from "./.module.scss";
import { Link } from "react-router-dom";
import {
  HomeIcon,
  FavIcon,
  ResIcon,
  FacebookIcon,
  TwitterIcon,
  Instagramicon,
} from "../../UI/icons";
import { FaLinkedinIn, FaTiktok } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";

export default function MobFixedBar() {
  const [bottomPage, setBottomPage] = useState(false);
  const [openlinksMenu, setOpenLinksMenu] = useState(false);
  const links = [
    {
      url: "https://www.facebook.com/Pick.a.UAE",
      icon: <FacebookIcon />,
    },
    {
      url: "https://twitter.com/picka55310807",
      icon: <TwitterIcon />,
    },
    {
      url: "https://www.instagram.com/pick.a_uae/",
      icon: <Instagramicon />,
    },
    {
      url: "https://www.tiktok.com/@pick.a.app?lang=en",
      icon: <FaTiktok className={styles.social__icon} />,
    },
    {
      url: "https://www.linkedin.com/company/pick-a/",
      icon: <FaLinkedinIn className={styles.social__icon} />,
    },
  ];

  useEffect(() => {
    const controlNavbar = () => {
      if (
        window.innerHeight + window.scrollY >=
        document.body.scrollHeight - 62
      ) {
        setBottomPage(true);
      } else {
        setBottomPage(false);
      }
    };

    window.addEventListener("scroll", controlNavbar);

    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  const closeDropDownMenu = (e) => {
    if (e.target.id !== "button-open-links-menu") {
      setOpenLinksMenu(false);
    }
  };
  useEffect(() => {
    document.body.addEventListener("click", closeDropDownMenu);

    return () => {
      document.body.removeEventListener("click", closeDropDownMenu);
    };
  }, []);

  return (
    <div className="d-md-none">
      <div
        className={`${styles.mob__fixed__bar__wrapper} ${
          bottomPage ? styles.bottom__page : ""
        }`}
      >
        <div className={`container__wrapper`}>
          <div className={`${styles.mob__fixed__bar}`}>
            <ul className={styles.mob__nav__list}>
              <li className={styles.mob__nav__list__item}>
                <Link to={"/favourites"} className={styles.mob__nav__link}>
                  <FavIcon />
                </Link>
              </li>
              <li
                className={`${styles.mob__nav__list__item} ${styles.mob__nav__home}`}
              >
                <Link to={"/home"} className={styles.mob__nav__link}>
                  <HomeIcon />
                </Link>
              </li>
              <li className={styles.mob__nav__list__item}>
                <Link to={"/restaurants"} className={styles.mob__nav__link}>
                  <ResIcon />
                </Link>
              </li>
              <li className={styles.drop__btn} id="button-open-links-menu">
                <button
                  id="button-open-links-menu"
                  onClick={(e) => {
                    setOpenLinksMenu((prev) => !prev);
                    e.stopPropagation();
                  }}
                >
                  <IoMdArrowDropdown />
                </button>
                {openlinksMenu && (
                  <ul className={styles.links__list}>
                    {links.map((link, i) => (
                      <li key={i}>
                        <a href={link.url} target="_blank" rel="noreferrer">
                          {link.icon}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
