const mealsCategoryReducer = (meals = [], action) => {
  switch (action.type) {
    case "GET__MEALS__CATEGORY":
      return [...meals, ...action?.payload];
    case "CLEAR__MEALS__CATEGORY":
      return [...action?.payload];
    default:
      return [...meals];
  }
};

export default mealsCategoryReducer;
