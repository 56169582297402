// import api from "../../../api";

const getAllRestaurants = () => async (dispatch) => {
  try {
    // const res = await api.get("/view-restaurants");

    var myHeaders = new Headers();
    myHeaders.append(
      "latitude",
      JSON.parse(window.localStorage.getItem("location"))?.lat
    );
    myHeaders.append(
      "longitude",
      JSON.parse(window.localStorage.getItem("location"))?.lng
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    const res = await fetch(
      "https://dashboard.pick-a.net/api/lastUpdatedRestaurants",
      requestOptions
    );
    const data = await res.json();
    dispatch({
      type: "GET__ALL__RESTAURANTS",
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: "GET__ALL__RESTAURANTS",
      payload: error.response.data,
    });
  }
};

export default getAllRestaurants;
