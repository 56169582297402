import React, { useEffect, useRef, useState } from "react";
import styles from "./.module.scss";
import { AiFillCaretDown } from "react-icons/ai";
import { RiSearchLine } from "react-icons/ri";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { getSearchRestaurants, getMostSearch } from "../../redux/actions";

// DROPDOWN MENUS
import CartDropdown from "../CartDropdown";
import UserDropdown from "../UserDropdown";
import NotificationsDropdown from "../NotificationsDropdown";

import { FilterIcon, CartIcon, BillIcon, UserIcon } from "../../UI/icons";
import SearchMenu from "../SearchMenu";

const mapStateToProps = (state) => state;
const mapDispatchToProps = { getSearchRestaurants, getMostSearch };

const MainHeader = ({
  loggedUser,
  userCart,
  getMostSearch,
  mostSearch,
  getSearchRestaurants,
  searchResults,
}) => {
  const headerRef = useRef();
  const [showHeader, setShowHeader] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [ShowCart, setShowCart] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [ShowMessages, setShowMessages] = useState(false);
  const [ShowNotifications, setShowNotifications] = useState(false);
  const [searchMenu, setSearchMenu] = useState(false);
  const [term, setTerm] = useState(false);
  const [debounceSearch, setDebounceSearch] = useState(term);
  const [loading, setLoading] = useState(false);

  const closeDropDownMenu = (e) => {
    if (e.target.id !== "search__input") {
      setSearchMenu(false);
    }
  };
  useEffect(() => {
    document.body.addEventListener("click", closeDropDownMenu);

    return () => {
      document.body.removeEventListener("click", closeDropDownMenu);
    };
  }, []);

  useEffect(() => {
    const controlNavbar = () => {
      if (document.body.getBoundingClientRect().top < 0) {
        setScrollPosition(document.body.getBoundingClientRect().top);
        setShowHeader(
          document.body.getBoundingClientRect().top > scrollPosition
        );
      } else {
        setShowHeader(null);
      }
    };

    window.addEventListener("scroll", controlNavbar);

    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [scrollPosition]);

  useEffect(() => {
    setLoading(true);
    setSearchMenu(true);
    const timeOut = setTimeout(() => {
      setDebounceSearch(term);
    }, 1200);
    return () => clearTimeout(timeOut);
  }, [term]);

  // useEffect(() => {
  //   (async () => {
  //     if (debounceSearch) {
  //       await getSearchRestaurants(debounceSearch);
  //       setLoading(false);
  //     } else {
  //       setSearchMenu(false);
  //       setLoading(false);
  //     }
  //   })();
  // }, [debounceSearch, getSearchRestaurants]);

  return (
    <>
      <header
        ref={headerRef}
        className={`${styles.main__header} ${
          showHeader === null
            ? ""
            : showHeader
            ? styles.header__show
            : styles.header__hide
        }`}
      >
        <div className={`container__wrapper ${styles.main__header__wrapper}`}>
          <div className={`row g-0 ${styles.header__wrapper}`}>
            <div className="col-9 col-sm-10 col-md-7">
              <div className={styles.search__form__container}>
                <form
                  className={styles.search__form}
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <RiSearchLine className={styles.search__icon} />
                  <input
                    onChange={(e) => setTerm(e.target.value)}
                    value={term || ""}
                    className={styles.search__input__field}
                    autoComplete="off"
                    onFocus={async () => {
                      setLoading(true);
                      setSearchMenu(true);
                      await getMostSearch();
                      setLoading(false);
                    }}
                    type="text"
                    placeholder="Search Food or Resturant ..."
                    id="search__input"
                  />
                  {/* {term && searchMenu ? (
                    <SearchMenu
                      onClose={setSearchMenu}
                      restaurants={searchResults}
                      loading={loading}
                    />
                  ) : null} */}
                  {mostSearch.length && searchMenu ? (
                    <SearchMenu
                      onClose={setSearchMenu}
                      loading={loading}
                      mostSearch={mostSearch}
                      setTerm={setTerm}
                    />
                  ) : null}
                </form>
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target={`#search__filters__modal`}
                  className={styles.filter_btn}
                >
                  <FilterIcon />
                </button>
              </div>
            </div>
            <div className="col-3 col-sm-2 col-md-5">
              {isEmpty(loggedUser) && (
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className={styles.signup_login__btn}
                    data-bs-toggle="modal"
                    data-bs-target={`#member__area__modal`}
                    id="open__member__area__form"
                  >
                    <UserIcon />
                    <span className="d-none d-md-inline-block">
                      Sign In/Sign up
                    </span>
                  </button>
                </div>
              )}
              {!isEmpty(loggedUser) && (
                <ul className={styles.user__actions__list}>
                  <li className={styles.list__item}>
                    <div className="dropdown">
                      <button
                        className={`${styles.user__settings_list} dropdown-toggle`}
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-auto-close="outside"
                      >
                        <CartIcon />
                        {userCart?.items?.length && (
                          <span className={styles.count__wrapper}>
                            {userCart?.items?.length}
                          </span>
                        )}
                      </button>
                      <CartDropdown showHeader={showHeader} />
                    </div>
                  </li>
                  <li className={styles.list__item}>
                    <div className="dropdown">
                      <button
                        className={`${styles.user__settings_list} dropdown-toggle`}
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-auto-close="outside"
                        data-bs-display="static"
                      >
                        <BillIcon />
                      </button>
                      <NotificationsDropdown showHeader={showHeader} />
                    </div>
                  </li>
                  <li className="ms-3">
                    <div className="dropdown">
                      <button
                        className={`btn dropdown-toggle ${styles.user__settings_list}`}
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-auto-close="auto-close"
                      >
                        <div
                          className={`${styles.user__avatar__container} me-3`}
                        ></div>
                        <div className={`mx-2 ${styles.user__info}`}>
                          <p className={`${styles.user__name}`}>
                            {loggedUser?.name ||
                              localStorage.getItem("user__name")}
                          </p>
                          <p className={`${styles.user__role}`}>Customer</p>
                        </div>
                        <AiFillCaretDown className="ms-2" />
                      </button>
                      <UserDropdown
                        showHeader={showHeader}
                        setShowCart={setShowCart}
                        setShowMessages={setShowMessages}
                        setShowNotifications={setShowNotifications}
                      />
                    </div>
                  </li>
                </ul>
              )}
            </div>
          </div>
          {/* )} */}
        </div>
      </header>
      {ShowCart && <CartDropdown notMenu={true} onClose={setShowCart} />}
      {/* {ShowMessages && (
        <MessagesDropdown notMenu={true} onClose={setShowMessages} />
      )} */}
      {ShowNotifications && (
        <NotificationsDropdown notMenu={true} onClose={setShowNotifications} />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MainHeader);
