import React, { useEffect, useLayoutEffect } from "react";
import { connect } from "react-redux";
import {
  updateCurrentUser,
  updateLocation,
  getCurrencyFormat,
} from "../../redux/actions";

export const Navigation = ({
  serverResponse,
  updateCurrentUser,
  updateLocation,
  getCurrencyFormat,
}) => {
  const getUserGeoLocation = () => {
    if (
      window.location.pathname.includes("/cancel-account") ||
      window.location.pathname.includes("/deletion-account")
    )
      return;
    if (window.navigator.geolocation) {
      let latitude, longitude;
      window.navigator.geolocation.getCurrentPosition(function (position) {
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;
        window.localStorage.setItem(
          "currentLocation",
          JSON.stringify({
            lat: latitude,
            lng: longitude,
          })
        );
      });
    }
  };

  useLayoutEffect(() => {
    updateCurrentUser();
    updateLocation();
    getUserGeoLocation();
    getCurrencyFormat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (serverResponse?.data?.auth_token) {
      localStorage.setItem("user", JSON.stringify(serverResponse.data));
      localStorage.setItem("auth_token", serverResponse.data.auth_token);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    if (serverResponse?.data?.user_data) {
      localStorage.setItem(
        "user",
        JSON.stringify(serverResponse?.data?.user_data)
      );
      localStorage.setItem(
        "auth_token",
        serverResponse?.data?.user_data?.auth_token
      );
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }, [serverResponse]);

  return <></>;
};

const mapStateToProps = (state) => state;
const mapDispatchToProps = {
  updateCurrentUser,
  updateLocation,
  getCurrencyFormat,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
