import React, { useEffect, useState } from "react";
import SubmitBtn from "../../components/SubmitBtn";
import { Link, useNavigate } from "react-router-dom";
import api from "../../api";
import { setCustomAlert } from "../../redux/actions";
import { connect } from "react-redux";
import styles from "./.module.scss";

const mapStateToProps = (state) => state;
const mapDispatchToProps = { setCustomAlert };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(function ConfirmDeleteAccount({ setCustomAlert }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const url = window.location.href;
    const token = url.split("token=")[1];
    console.log(token);
    if (!token) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ConfirmDelete = async () => {
    const url = window.location.href;
    const url2 = url.split("token=")[1];
    const token = url2.split("&expires")[0];
    setIsLoading(true);
    try {
      await api.post("/delete-account/confirm", {
        token,
        confirmation: "yes",
      });
      setCustomAlert({
        msgType: "success",
        message: "Deleted successfully!",
      });
      setIsLoading(false);
      localStorage.removeItem("user");
      localStorage.removeItem("auth_token");
      localStorage.removeItem("user__name");
      //   setTimeout(() => {
      //     window.location.reload();
      //   }, 1000);
      navigate("/");
    } catch (err) {
      console.log(err);
      setCustomAlert({
        msgType: "error",
        message: err?.response?.data?.message || "Something went wrong!",
      });
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  return (
    <div className={styles.page}>
      <h3 className={styles.title}>
        Are you sure you want to delete your account?
      </h3>
      <p className={styles.description}>
        You can delete your account at any time. If you change your mind, you
        can't get it back
      </p>
      <div className={styles.btns}>
        <SubmitBtn
          smBorder={true}
          solidStyle={true}
          type="button"
          disabled={isLoading}
          onClick={ConfirmDelete}
        >
          Yes
        </SubmitBtn>
        <Link to="/" className={styles.cancel_btn}>
          No
        </Link>
      </div>
    </div>
  );
});
