import api from "../../../api";

const getMealsCategory =
  ({ id, skip }) =>
  async (dispatch) => {
    try {
      const res = await api.get(
        `/view-PaginateItems?category_id=${id}&take=10&skip=${skip}`
      );
      dispatch({
        type: "GET__MEALS__CATEGORY",
        payload: res.data.data,
      });
      return res.data.data;
    } catch (error) {
      dispatch({
        type: "GET__MEALS__CATEGORY",
        payload: error.response.data,
      });
    }
  };

export default getMealsCategory;
