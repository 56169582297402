import React from "react";
import styles from "./.module.scss";
import {
  PickALogo,
  ResIcon,
  FavIcon,
  HomeIcon,
  FacebookIcon,
  TwitterIcon,
  Instagramicon,
} from "../../UI/icons";
import { FaLinkedinIn, FaTiktok } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Sidebar() {
  const links = [
    {
      url: "https://www.facebook.com/Pick.a.UAE",
      icon: <FacebookIcon />,
    },
    {
      url: "https://twitter.com/picka55310807",
      icon: <TwitterIcon />,
    },
    {
      url: "https://www.instagram.com/pick.a_uae/",
      icon: <Instagramicon />,
    },
    {
      url: "https://www.tiktok.com/@pick.a.app?lang=en",
      icon: <FaTiktok className={styles.social__icon} />,
    },
    {
      url: "https://www.linkedin.com/company/pick-a/",
      icon: <FaLinkedinIn className={styles.social__icon} />,
    },
  ];

  return (
    <div className="d-none d-md-block">
      <div className={styles.sidebar}>
        <div className={styles.pick_a__logo} title="Pick.a">
          <Link to="/">
            <PickALogo />
          </Link>
        </div>
        <ul className={styles.sidebar__nav__links}>
          <li className={styles.sidebar__list__item}>
            <Link to="/home" className={styles.sidebar__nav__link}>
              <div className={styles.icon__container}>
                <HomeIcon />
              </div>
              <span>home</span>
            </Link>
          </li>
          <li className={styles.sidebar__list__item}>
            <Link to="/favourites" className={styles.sidebar__nav__link}>
              <div className={styles.icon__container}>
                <FavIcon />
              </div>
              <span>favourites</span>
            </Link>
          </li>
          <li className={styles.sidebar__list__item}>
            <Link to="/restaurants" className={styles.sidebar__nav__link}>
              <div className={styles.icon__container}>
                <ResIcon />
              </div>
              <span>restaurants</span>
            </Link>
          </li>
        </ul>
        <ul className={styles.links__list}>
          {links.map((link, i) => (
            <li key={i}>
              <a href={link.url} target="-blank" className={styles.link}>
                {link.icon}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
